<template>
  <b-row class="filters-container">
    <b-col cols="5">
      <label for="">Paciente</label>
      <div class="search-container">
        <Search class="icon" />
        <b-form-input
          v-model="filters.patient"
          debounce="500"
          placeholder="Buscar paciente"
        />
      </div>
    </b-col>

    <b-col cols="3">
      <label for="guideNumber">Nº da guia</label>
      <b-form-input
        id="guideNumber"
        v-model="filters.guideNumber"
        placeholder="Pesquisar"
        debounce="500"
      />
    </b-col>

    <b-col cols="2">
      <label for="guideType">Tipo de guia</label>
      <v-autocomplete
        id="guideType"
        v-model="filters.guideType"
        :options="guideTypes"
        placeholder="Selecionar"
      />
    </b-col>

    <b-col cols="2">
      <label for="condition">Condição</label>
      <div class="multiselect">
        <v-multiselect
          id="condition"
          :value="conditions"
          :options="conditions"
          v-on:onChange="changeSelectedConditions"
        />
      </div>
    </b-col>
  </b-row>
</template>

<script>
import Search from '@/assets/icons/search.svg'
import Autocomplete from '@/components/Autocomplete'
import CheckboxSelect from '../../../CheckboxSelect.vue'

export default {
  components: {
    Search,
    'v-autocomplete': Autocomplete,
    'v-multiselect': CheckboxSelect
  },
  data() {
    return {
      filters: {
        patient: null,
        guideNumber: null,
        guideType: null,
        conditions: []
      },
      guideTypes: [
        {
          label: 'Consulta',
          value: 'Consultation'
        },
        {
          label: 'SP/SADT',
          value: 'SpSadt'
        },
        {
          label: 'Solicitação de internação',
          value: 'HospitalizationRequest'
        },
        {
          label: 'Resumo de internação',
          value: 'HospitalizationSummary'
        },
        {
          label: 'Honorários',
          value: 'Fee'
        },
        {
          label: 'Despesas',
          value: 'Expense'
        }
      ],
      conditions: [
        'Todos',
        'Guias conferidas',
        'Guias não conferidas',
        'Guias com lote gerado',
        'Guias sem lote'
      ]
    }
  },
  methods: {
    changeSelectedConditions(newValue) {
      this.filters.conditions = newValue
    }
  },
  watch: {
    filters: {
      handler(newValue) {
        this.$emit('change', newValue)
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.filters-container {
  margin: 20px 0;

  label {
    font-weight: 700;
  }

  .search-container {
    display: flex;
    flex-direction: row;
    position: relative;

    .icon {
      position: absolute;
      width: 24px;
      height: 24px;
      stroke: var(--neutral-500);
      height: 100%;
      margin-left: 10px;
    }

    input {
      padding-left: 40px !important;
    }
  }
}
</style>
